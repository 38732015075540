import { useContext } from 'react'
import storesContext from 'providers/storesContext'
import { faFolder, faImages, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LinearProgress } from '@mui/material'
import Button from 'components/UI/Button'
import Modal from 'components/UI/Modal'
import { ModalButtonsDisplay, ModalSize } from 'components/UI/Modal/Modal'
import PackageLabel from 'components/UI/PackageLabel/PackageLabel'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Photograph } from 'shared/models/Photograph'
import { PackageCartLine } from '../../../../shared/models/Cart/CartLine/PackageCartLine'
import { isNil } from 'lodash'
import { BurstCartLine } from 'shared/models/Cart/CartLine/BurstCartLine'

type PackageLineProps = {
  line: PackageCartLine | BurstCartLine
}

const PackageLine = ({ line }: PackageLineProps) => {
  const { cartStore } = useContext(storesContext)!
  const history = useHistory()
  const { t } = useTranslation()

  const [progress, setProgress] = useState(0)
  const [clearTimeoutTool, setClearTimeoutTool] = useState<NodeJS.Timeout | null>(null) // Change type to NodeJS.Timeout
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [selectedPackagePhotos, setSelectedPackagePhotos] = useState<Photograph[]>([])
  const [showPackageImagesModal, setShowPackageImagesModal] = useState(false)

  const isEventPackage = !isNil(line.event)
  const currency = isEventPackage
    ? line.event!.currency.toUpperCase()
    : line.albums[0].currency.toUpperCase()

  useEffect(() => {
    const interval = 20
    const steps = 115 / (4000 / interval)
    const timer = setInterval(() => {
      setProgress((prevProgress) => Math.max(0, prevProgress - steps))
    }, interval)
    return () => {
      clearInterval(timer)
    }
  }, [])

  const goToEventOrAlbum = () => {
    const location = isEventPackage
      ? `/events/${line.event!.landingPath}`
      : `/feed/albums/${line.albums[0]?.id}`
    history.push(location)
  }

  const viewPackagePhotos = () => {
    setShowPackageImagesModal(true)
    setSelectedPackagePhotos(line.previewPhotographs) //TODO: This view should be a paginated view with all the photos in the package
  }

  const removePackageFromCart = () => {
    cartStore.removeLine(line)
  }

  const deleteItem = () => {
    setProgress(100)
    setDeleteConfirmation(true)
    const deletePictureTimeout = setTimeout(removePackageFromCart, 4000)
    setClearTimeoutTool(deletePictureTimeout)
  }

  const undoDelete = () => {
    if (clearTimeoutTool !== null) {
      clearTimeout(clearTimeoutTool)
      setClearTimeoutTool(null)
    }
    setDeleteConfirmation(false)
  }

  return (
    <>
      {!deleteConfirmation && (
        <div className="w-full flex py-2.5 border-b relative bg-white border-b-lg md:rounded-b-lg">
          <div className="flex items-center w-full md:w-1/2 cursor-zoom-in">
            <FontAwesomeIcon icon={faFolder} className="hidden md:text-2xl md:mr-2" />
            <div className="h-full w-5/6 relative pl-2 md:px-0 md:pl-1">
              {line.previewPhotographs.map((photograph, index) => {
                // Only show first 3 photos to create package effect
                if (index >= 3) return null

                return (
                  <div
                    key={photograph.id}
                    className={`absolute w-full h-full transition-transform duration-300 hover:-translate-y-1 ${
                      index === 0 ? 'z-30' : index === 1 ? 'z-20 rotate-3' : 'z-10 rotate-6'
                    }`}
                    style={{
                      transform: `translateY(${index * 4}px)`,
                    }}
                  >
                    <img
                      src={photograph.url}
                      alt={photograph.id}
                      onClick={viewPackagePhotos}
                      className="object-cover object-center w-full h-full cursor-zoom-in md:rounded-md shadow-lg"
                    />

                    {index === 0 && (
                      <div className="absolute bottom-2 right-2 bg-white/90 px-2 py-1 rounded-md text-sm font-medium shadow-md">
                        {line.quantityOfPhotographs} {t('photos')}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>

          <div className="w-full flex flex-col justify-between ml-2 md:ml-6 md:mb-3">
            <div className="flex flex-col items-end font-semibold pt-2 pr-2 md:pl-2">
              <div className="w-full flex-col md:flex md:items-end">
                <div className="flex flex-col items-end">
                  <span className="font-light text-sm justify-end text-lumepic-grey line-through">
                    {currency} {line.previewPhotographs[0].price * line.quantityOfPhotographs}
                  </span>
                  {currency} {line.totalPrice}
                </div>
                <div className="md:w-1/2 ">
                  <PackageLabel />
                </div>
              </div>
            </div>

            {/* LINE INFO & ACTIONS */}
            <div className="flex flex-col items-start justify-end gap-2 mt-4">
              <div onClick={goToEventOrAlbum} className="flex items-center gap-2 cursor-pointer">
                <i className="text-lumepic-grey">
                  <FontAwesomeIcon icon={faImages} />
                </i>
                <span className="text-sm text-lumepic-grey lg:text-base">
                  {isEventPackage ? t('Go to event') : t('Go to album')}
                </span>
              </div>
              <div
                onClick={() => deleteItem()}
                className="ml-0.5 flex gap-2 items-center cursor-pointer"
              >
                <i className="text-lumepic-grey">
                  <FontAwesomeIcon icon={faTrashAlt} />
                </i>
                <span className="text-sm text-lumepic-grey lg:text-base">{t('Delete')}</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteConfirmation && (
        <div className="w-full my-3 bg-bg_details">
          <LinearProgress variant="determinate" color="primary" value={progress} />
          <div className="flex flex-col items-center justify-center gap-2 p-5 min-h-20 max-h-48">
            <p className="mb-0 font-medium">{t('Picture deleted')}</p>
            <Button btnType="TertiaryAction" onClick={() => undoDelete()}>
              {t('Undo action')}
            </Button>
          </div>
        </div>
      )}
      <Modal
        onCancel={() => setShowPackageImagesModal(false)}
        opened={showPackageImagesModal}
        modalSize={ModalSize.MEDIUM}
        buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
        displayCloseIcon={false}
      >
        <div
          className={`flex flex-col p-3 ${
            selectedPackagePhotos.length === 2 ? 'md:grid-cols-2' : 'md:grid-cols-3'
          } gap-4 md:p-4 overflow-y-scroll`}
          style={{ backdropFilter: 'blur(8px)' }}
        >
          {selectedPackagePhotos.map((photo) => (
            <div key={photo.id} className="flex justify-center items-center">
              <img src={photo.url} alt={photo.id} style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </div>
          ))}
        </div>
      </Modal>
    </>
  )
}

export default observer(PackageLine)
