import React from 'react'
import { observer } from 'mobx-react'
import Cart from '../../../../../../shared/models/Cart/Cart'
import { useTranslation } from 'react-i18next'
import Spinner from 'components/UI/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { CartLine, CartLineType } from '../../../../../../shared/models/Cart/CartLine/CartLine'

type DiscountsListProps = {
  cart: Cart
  lines: CartLine[]
  isLoadingDiscounts: boolean
}

const DiscountsList = ({ cart, lines, isLoadingDiscounts }: DiscountsListProps) => {
  const { t } = useTranslation()

  if (isLoadingDiscounts) {
    return (
      <div className="flex justify-between">
        <p className="mb-0 font-medium">{t('Discounts')}</p>
        <Spinner noStyles size={15} color="info" />
      </div>
    )
  }

  const isPackageGroup = lines[0]?.type === CartLineType.Package
  const hasDiscounts = isPackageGroup
    ? cart.getPackagesDiscountAmount(lines) > 0
    : cart.getQuantityDiscountAmount(lines) > 0 || cart.getCodeDiscountsAmount(lines) > 0

  if (!hasDiscounts) {
    return null
  }

  return (
    <>
      {hasDiscounts && (
        <div>
          <div className="flex justify-between">
            <p className="text-lumepic-light_black font-medium">{t('Discounts')}</p>
          </div>

          <>
            {cart.getQuantityDiscountAmount(lines) > 0 && (
              <div className="flex justify-between items-center mt-1">
                <div className="flex gap-1">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="text-lumepic-success_dark_green font-light"
                  />
                  <p className="text-xs font-light text-lumepic-success_dark_green">
                    {t('Quantity discounts')}
                  </p>
                </div>
                <p className="text-xs font-light text-lumepic-grey">
                  {cart.currency?.toUpperCase() || 'UYU'}{' '}
                  {cart.getQuantityDiscountAmount(lines).toFixed(2)}
                </p>
              </div>
            )}

            {cart.getCodeDiscountsAmount(lines) > 0 && (
              <>
                {cart.codeDiscounts.map((codeDiscount, i) => (
                  <div className="flex justify-between items-center mt-1" key={`discount_${i}`}>
                    <div className="flex gap-1">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-lumepic-success_dark_green font-light"
                      />
                      <p className="text-xs font-light text-lumepic-success_dark_green">
                        {t('Code')}:
                      </p>
                      <p className="mb-0 font-light text-lumepic-success_dark_green text-xs">
                        {codeDiscount.code}
                      </p>
                    </div>
                    <p className="mb-0 text-xs font-light text-lumepic-grey">
                      {cart.currency?.toUpperCase() || 'UYU'}{' '}
                      {cart.getCodeDiscountsAmount(lines).toFixed(2)}
                    </p>
                  </div>
                ))}
              </>
            )}
          </>

          {isPackageGroup && cart.getPackagesDiscountAmount(lines) > 0 && (
            <div className="flex justify-between items-center mt-1">
              <div className="flex gap-1">
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-lumepic-success_dark_green font-light"
                />
                <p className="text-xs font-light text-lumepic-success_dark_green">
                  {t('Package discounts')}
                </p>
              </div>
              <p className="mb-0 text-xs font-light text-lumepic-grey">
                {cart.currency?.toUpperCase() || 'UYU'}{' '}
                {cart.getPackagesDiscountAmount(lines).toFixed(2)}
              </p>
            </div>
          )}

          {cart.getBurstsDiscountAmount(lines) > 0 && (
            <div className="flex justify-between items-center mt-1">
              <div className="flex gap-1">
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-lumepic-success_dark_green font-light"
                />
                <p className="text-xs font-light text-lumepic-success_dark_green">
                  {t('Burst discounts')}
                </p>
              </div>
              <p className="mb-0 text-xs font-light text-lumepic-grey">
                {cart.currency?.toUpperCase() || 'UYU'}{' '}
                {cart.getBurstsDiscountAmount(lines).toFixed(2)}
              </p>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default observer(DiscountsList)
