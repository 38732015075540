import React from 'react'
import { CartLine, CartLineType } from '../../../../shared/models/Cart/CartLine/CartLine'
import PhotographCartLineComponent from './PhotographCartLine'
import PackageLine from './PackageLine'
import { PackageCartLine } from '../../../../shared/models/Cart/CartLine/PackageCartLine'
import { PhotographCartLine } from '../../../../shared/models/Cart/CartLine/PhotographCartLine'
import { observer } from 'mobx-react'
import { BurstCartLine } from 'shared/models/Cart/CartLine/BurstCartLine'

type CartLineProps = {
  line: CartLine
}

const CartLineComponent = ({ line }: CartLineProps) => {
  let viewComponent: JSX.Element | null = null
  switch (line.type) {
    case CartLineType.Photograph:
      viewComponent = <PhotographCartLineComponent line={line as PhotographCartLine} />
      break
    case CartLineType.Package:
      viewComponent = <PackageLine line={line as PackageCartLine} />
      break
    case CartLineType.Burst:
      viewComponent = <PackageLine line={line as BurstCartLine} />
      break
    default:
      break
  }
  return viewComponent
}

export default observer(CartLineComponent)
