import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import storesContext from 'providers/storesContext'
import { Photograph } from 'shared/models/Photograph'
import * as paths from '../../../routing/Paths'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button'
import EventDetailsStore from 'containers/Events/EventDetails/EventDetailsStore'
import AlbumDetailsStore from 'components/AlbumDetails/AlbumDetailsStore'
import { observer } from 'mobx-react'
import PackagePurchaseInfoModal from '../PackagePurchaseInfoModal/PackagePurchaseInfoModal'

type BurstButtonProps = {
  handleAddBurstClick: (burstPhotographs: Photograph[]) => void
  handleBuyBurst: (burst: Photograph[]) => void
  burstPhotographs: Photograph[]
  eventDetailsStore?: EventDetailsStore
  albumDetailsStore?: AlbumDetailsStore
}

const BurstButton = ({
  handleAddBurstClick,
  handleBuyBurst,
  burstPhotographs,
  eventDetailsStore,
  albumDetailsStore,
}: BurstButtonProps) => {
  const { t } = useTranslation()
  const { cartStore } = useContext(storesContext)!
  const history = useHistory()
  const [openModal, setOpenModal] = useState(false)

  const filteredPhotographs = burstPhotographs.flatMap((photograph) => photograph)
  const burstAlreadyInCart = filteredPhotographs.every((photograph) =>
    cartStore.alreadyInCart(photograph)
  )

  const handleClick = () => {
    if (burstAlreadyInCart) {
      history.push(paths.CART)
    } else {
      setOpenModal(true)
    }
  }

  const handleBuyNow = () => {
    handleBuyBurst(burstPhotographs)
    setOpenModal(false)
  }

  const handleAddToCart = () => {
    handleAddBurstClick(burstPhotographs)
    setOpenModal(false)
  }

  const buttonClasses = burstAlreadyInCart
    ? 'bg-primary_dark py-0.5 font-medium text-lumepicSm px-2.5 py-1'
    : 'bg-white border border-lumepic-light_black font-regular text-sm px-2.5 py-0.5 '

  const buttonType = burstAlreadyInCart ? 'PrimaryAction' : 'SecondaryAction'

  return (
    <>
      <div className="relative">
        <span className="absolute -right-0.5 -top-0.5 z-20 flex w-2 h-2">
          <span
            className={`absolute inline-flex w-full h-full rounded-full animate-ping ${
              burstAlreadyInCart ? 'bg-lumepic-light_black' : 'bg-primary'
            }`}
          ></span>
          <span
            className={`relative inline-flex w-2 h-2 rounded-full ${
              burstAlreadyInCart ? 'bg-lumepic-light_black' : 'bg-primary'
            }`}
          ></span>
        </span>
        <Button
          btnType={buttonType}
          onClick={handleClick}
          extraStyle={`cursor-pointer rounded-md animate-bounce text-black ${buttonClasses}`}
        >
          <span className="mr-1">{burstAlreadyInCart ? t('Go to cart') : t('Buy burst for')}</span>
          {!burstAlreadyInCart && (
            <strong>
              {`${(
                albumDetailsStore?.album || eventDetailsStore?.event
              )?.currency?.toUpperCase()} ${
                albumDetailsStore?.album?.burstPrice || eventDetailsStore?.event.burstPrice
              }`}
            </strong>
          )}
          {burstAlreadyInCart && <FontAwesomeIcon icon={faArrowRight} className="ml-0.5" />}
        </Button>
      </div>
      <PackagePurchaseInfoModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        handleBuyClick={handleBuyNow}
        handleAddBurstClick={handleAddToCart}
        isBurstPurchase={true}
        photographs={burstPhotographs}
        album={albumDetailsStore?.album}
        eventName={eventDetailsStore?.event.name}
        isLoading={cartStore.isLoading}
        isFaceRecognition={false}
      />
    </>
  )
}

export default observer(BurstButton)
