import { Skeleton } from '@mui/material'
import React from 'react'

const AlbumInformationSkeleton = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="w-full flex flex-col rounded-lg lg:flex-row lg:justify-between items-center bg-white">
        <div className="flex gap-2 md:gap-4 rounded-md px-2 py-2.5 w-full bg-white">
          <div className="flex flex-col gap-2">
            {/* Location */}
            <div className="flex items-center gap-2 text-base">
              <Skeleton variant="circular" width={24} height={24} /> {/* Icon */}
              <Skeleton variant="rounded" width={200} height={24} /> {/* Location text */}
            </div>

            {/* Date */}
            <div className="flex items-center gap-2">
              <Skeleton variant="circular" width={24} height={24} /> {/* Calendar icon */}
              <Skeleton variant="rounded" width={200} height={24} /> {/* Date text */}
            </div>

            {/* Photographer info */}
            <div className="flex items-center gap-2">
              <Skeleton variant="circular" width={24} height={24} /> {/* Photographer icon */}
              <Skeleton variant="rounded" width={200} height={24} /> {/* Photographer name */}
            </div>

            {/* Description */}
            <div className="flex items-center gap-2">
              <Skeleton variant="circular" width={24} height={24} /> {/* Info icon */}
              <Skeleton variant="rounded" width={200} height={24} /> {/* Description text */}
            </div>
          </div>
        </div>

        {/* Quantity discount section */}
        <div className="hidden md:block w-full lg:w-2/5 pr-2">
          <Skeleton variant="rounded" width="100%" height={120} />
        </div>
      </div>
      <div className="w-full flex flex-col rounded-lg lg:flex-row lg:justify-between items-center bg-white">
        <div className="flex gap-2 md:gap-4 rounded-md px-2 py-2.5 w-full bg-white">
          <Skeleton variant="rounded" height={30} width={150} />
        </div>
      </div>
    </div>
  )
}

export default AlbumInformationSkeleton
