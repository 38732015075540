import { CartLineType } from '../shared/models/Cart/CartLine/CartLine'
import Cart from '../shared/models/Cart/Cart'
import { PhotographCartLine } from '../shared/models/Cart/CartLine/PhotographCartLine'
import { PackageCartLine } from '../shared/models/Cart/CartLine/PackageCartLine'
import { LineItemBuilder } from './LineItemBuilder'
import CreatePurchaseInput, {
  CreatePurchaseLineItem,
  PurchasePhotographLineItem,
} from '../services/RequestInterfaces/Purchase/PurchaseRequestInterfaces'
import { BurstCartLine } from '../shared/models/Cart/CartLine/BurstCartLine'
import { isNil } from 'lodash'
import { Currency } from '../shared/util/Currency'
import Album from '../shared/models/Album'
import { Event } from '../shared/models/Event'

export class PurchaseBuilder {
  static BuildPurchaseFromCart(cart: Cart, printedPhotographs: boolean): CreatePurchaseInput {
    const lineItems: CreatePurchaseLineItem[] = cart.lines.map((line) => {
      let castedLine
      switch (line.type) {
        case CartLineType.Photograph:
          castedLine = line as PhotographCartLine
          return {
            photographId: castedLine.photograph.id,
          } as PurchasePhotographLineItem
        case CartLineType.Package:
          castedLine = line as PackageCartLine
          return LineItemBuilder.BuildTagPackageLineItem(
            castedLine.tagId,
            castedLine.albums,
            castedLine.event
          )
        case CartLineType.Burst:
          castedLine = line as BurstCartLine
          return LineItemBuilder.BuildBurstPackageLineItem(
            castedLine.burstId,
            castedLine.albums,
            castedLine.event,
            castedLine.photographIds
          )
      }
    })

    const discountCodes = cart.codeDiscounts.flatMap((discount) => {
      return discount.code
    })

    if (isNil(cart.currency)) {
      throw new Error('Error building purchase: Cart currency is not defined')
    }

    return {
      discountCodes,
      lineItems,
      printedPhotographs,
      currency: cart.currency,
    }
  }

  static BuildTagPackagePurchase(
    tagId: string,
    currency: Currency,
    albums?: Album[],
    event?: Event,
    discountCodes: string[] = [],
    photographIds?: string[]
  ) {
    const lineItem = LineItemBuilder.BuildTagPackageLineItem(tagId, albums, event, photographIds)

    return {
      discountCodes,
      lineItems: [lineItem],
      printedPhotographs: false,
      currency,
    }
  }

  static BuildBurstPurchase(
    tagId: string,
    currency: Currency,
    albums?: Album[],
    event?: Event,
    discountCodes: string[] = [],
    photographIds?: string[]
  ) {
    const lineItem = LineItemBuilder.BuildBurstPackageLineItem(tagId, albums, event, photographIds)

    return {
      discountCodes,
      lineItems: [lineItem],
      printedPhotographs: false,
      currency,
    }
  }
}
